// src/routes.js

import React from "react";
import { Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";

const routes = [
  {
    path: "/conflux/cspace",
    element: <Home />,
    title: "Stake - Core Space - Conflux Staking | Stake DxPool"
  },
  {
    path: "/conflux/cspace/dashboard",
    element: <Dashboard />,
    title: "Core Space - Conflux Staking Dashboard | Stake DxPool"
  },
  {
    path: "*",
    element: <Navigate to="/conflux/cspace" />,
    title: "Redirect - Stake DxPool"
  },
];

export default routes;
