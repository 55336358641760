// src/App.js

import { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { Spin, Layout } from "antd";
import "./App.css";
import { Header } from "./pages/components";
import routes from "./routes";

const { Content } = Layout;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function usePageTitle() {
  const location = useLocation();

  useEffect(() => {
    const currentRoute = routes.find(route => route.path === location.pathname);
    if (currentRoute) {
      document.title = currentRoute.title || "Stake - Core Space - Conflux Staking | Stake DxPool";
    }
  }, [location]);
}

function AppContent() {
  usePageTitle();

  return (
    <Layout className="layout">
      <div className="relative flex flex-col h-full overflow-x-hidden">
        <Header />
        <Content style={{ backgroundColor: '#ffffff' }}>
          <div>
            <Routes>
              {routes.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </Routes>
          </div>
        </Content>
        {/* <Footer style={{ textAlign: 'center' }}>PoS Pool</Footer> */}
      </div>
    </Layout>
  );
}

function App() {
  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-full h-full">
          <Spin className="w-20 h-20" />
        </div>
      }
    >
      <Router>
        <ScrollToTop />
        <AppContent />
      </Router>
    </Suspense>
  );
}

export default App;
