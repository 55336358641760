import { useEffect, useState, useCallback } from "react";
import { NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET } from "../constants";

export const useWalletInfo = () => {
  const [account, setAccount] = useState(undefined);
  const [chainId, setChainId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const allowedNetworkIds = [NETWORK_ID_CORE_MAINNET, NETWORK_ID_CORE_TESTNET];

  const handleAccountsChanged = useCallback((accounts) => {
    setAccount(accounts[0] || undefined);
  }, []);

  const handleChainChanged = useCallback(() => {
    fetchWalletInfo();
  }, []);

  const fetchWalletInfo = useCallback(async () => {
    if (!window.conflux) {
      console.error('Conflux not available');
      setIsLoading(false);
      return;
    }

    try {
      const [accounts, chainIdHex] = await Promise.all([
        window.conflux.request({ method: 'cfx_accounts' }),
        window.conflux.request({ method: 'cfx_chainId' })
      ]);

      handleAccountsChanged(accounts);
      setChainId(parseInt(chainIdHex, 16));
    } catch (error) {
      console.error('Error fetching wallet info:', error);
    } finally {
      setIsLoading(false);
    }
  }, [handleAccountsChanged]);

  useEffect(() => {
    const checkConfluxAndSetListeners = () => {
      if (window.conflux) {
        window.conflux.on('accountsChanged', handleAccountsChanged);
        window.conflux.on('chainChanged', handleChainChanged);
        fetchWalletInfo();
        return true;
      }
      return false;
    };
  
    const maxAttempts = 6; // 3000ms / 500ms = 6 attempts
    let attempts = 0;
  
    const intervalId = setInterval(() => {
      if (checkConfluxAndSetListeners() || ++attempts >= maxAttempts) {
        clearInterval(intervalId);
        if (attempts >= maxAttempts) {
          console.log('Failed to detect Conflux after 3 seconds');
          setAccount(undefined);
          setChainId(null);
          setIsLoading(false);
        }
      }
    }, 500);
  
    return () => {
      clearInterval(intervalId);
      if (window.conflux) {
        window.conflux.removeListener('accountsChanged', handleAccountsChanged);
        window.conflux.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [handleAccountsChanged, handleChainChanged, fetchWalletInfo]);

  const isNetworkMatch = chainId !== null && allowedNetworkIds.includes(chainId);

  return {
    account,
    isNetworkMatch,
    isLoading,
    networkError: !isLoading && !isNetworkMatch && !!account
  };
};

export default useWalletInfo;