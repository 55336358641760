import React, { useEffect, useState, useCallback } from 'react';
import { Tooltip } from 'antd';
import { getRewardInfo } from '../../api';
import { useAccount, useChainId } from '../../hooks/useWallet';
import { InfoCircleOutlined } from '@ant-design/icons';
import { formatNumber } from '../../utils';
import usePoolContract from '../../hooks/usePoolContract';
import useCurrentNetwork from '../../hooks/useCurrentNetwork';
import TransactionModal from './components/TransactionModal';
import { formatWeiNumbers } from '../../utils/index'

function Summary({ triggerOperationUpdate }) {
  const [apr, setApr] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [balance, setBalance] = useState(0);
  const [unClaimedRewards, setUnClaimedRewards] = useState(0);
  const [withdrawableAmount, setWithDrawableAmount] = useState(0);
  const [displayLoading, setDisplayLoading] = useState(false);

  const { account, isAccountLoading } = useAccount();
  const chainId = useChainId();
  const { isTestNetEnv, isLoading } = useCurrentNetwork();

  const { contract: posPoolContract, isContractLoading } = usePoolContract();

  const getRewardSummary = async () => {
    setDisplayLoading(true)

    if (!account) {
      setApr(0);
      setTotalRewards(0);
      setBalance(0);
      setDisplayLoading(false)
      return;
    };

    const params = {
      network_type: isTestNetEnv ? 'testnet' : 'mainnet',
      address: account,
    }

    if ((params.network_type === 'testnet' && !params.address.includes('cfxtest:')) || (params.network_type === 'mainnet' && params.address.includes('cfxtest:'))) {
      return
    }

    const res = await getRewardInfo(params);

    setApr(res.data.average_apr);
    setTotalRewards(res.data.total_earn);
    setBalance(res.data.balance);

    setDisplayLoading(false)
  }

  const getWithdrawableAmount = async () => {
    if (!account || !posPoolContract) {
      setWithDrawableAmount(0);
      return;
    }

    const res = await posPoolContract.userSummary(account)
    // const res = await posPoolContract.userSummary('cfx:aajn6kfvhvxvdb6t5u1nk7ektgvte1x7z2y1s9pp9k')

    setWithDrawableAmount(res[3][0] * 1000)
  }

  const getUnclaimedRewards = async () => {
    if (!account || !posPoolContract) {
      setUnClaimedRewards(0);
      return;
    }
    // eslint-disable-next-line no-unused-vars
    const [reward1, reward2, reward3] = await posPoolContract.getUserRewardInfo(account)

    const decimalString = formatWeiNumbers(reward2.toString(), 8);

    setUnClaimedRewards(decimalString)
  }

  const handleUpdate = useCallback(() => {
    if (!isLoading && !isAccountLoading && !isContractLoading) {
      getRewardSummary();
    }

    if (!isContractLoading) {
      getWithdrawableAmount();
      getUnclaimedRewards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId, isTestNetEnv, isContractLoading, isAccountLoading]);

  useEffect(() => {
    handleUpdate();
  }, [account, chainId, handleUpdate, isTestNetEnv, isLoading, isContractLoading, isAccountLoading]);

  return (
    <div className="p-6 my-6 bg-base-200 rounded-2xl">
      <div className="grid grid-cols-1 gap-2 lg:gap-6 lg:grid-cols-3">
        <div className="lg:col-span-1">
          <span className="block text-base font-medium text-gray-text">Total Rewards</span>
          {displayLoading ? (
            <strong className="block mt-2 ml-1 h-[30px] w-1/2 bg-[#E2E8F0] rounded animate-pulse"></strong>
          ) : (
            <strong className="block mt-2 text-2lg">{formatNumber(totalRewards, 8)} CFX</strong>
          )}
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">APR</span>
          {displayLoading ? (
            <strong className="block mt-2 ml-1 h-[30px] w-1/2 bg-[#E2E8F0] rounded animate-pulse"></strong>
          ) : (
            <strong className="block mt-2 text-2lg">{formatNumber(apr, 8)} %</strong>
          )}
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">Balance</span>
          {displayLoading ? (
            <strong className="block mt-2 ml-1 h-[30px] w-1/2 bg-[#E2E8F0] rounded animate-pulse"></strong>
          ) : (
            <strong className="block mt-2 text-2lg">{formatNumber(balance, 8)} CFX</strong>
          )}
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">Unclaimed CFX</span>
          <div className="flex items-center mt-2">
            {displayLoading ? (
              <strong className="block ml-1 h-[30px] w-1/2 bg-[#E2E8F0] rounded animate-pulse"></strong>
            ) : (
              <strong className="block text-2lg">{unClaimedRewards} CFX</strong>
            )}

            {Number(unClaimedRewards) > 0 &&
              <TransactionModal
                type="Claim"
                title="Claim Rewards"
                available={unClaimedRewards}
                onUpdate={handleUpdate}
                triggerOperationUpdate={triggerOperationUpdate}
              />
            }
          </div>
        </div>
        <div className="lg:col-span-1">
          <span className="block mt-4 text-base font-medium text-gray-text lg:mt-0">
            <span className="mr-2">Withdrawable Amount </span>
            <Tooltip title="It will take few minutes to update this value after your unstake" color="white" key="white" overlayInnerStyle={{ color: '#333' }}><InfoCircleOutlined /></Tooltip>
          </span>
          <div className="flex items-center mt-2">
            {displayLoading ? (
              <strong className="block ml-1 h-[30px] w-1/2 bg-[#E2E8F0] rounded animate-pulse"></strong>
            ) : (
              <strong className="block text-2lg">{Number(withdrawableAmount) ? Number(withdrawableAmount) : 0} CFX</strong>
            )}
            
            {Number(withdrawableAmount) > 0 &&
              <TransactionModal
                type="Withdraw"
                title="Request Withdrawal"
                available={withdrawableAmount}
                onUpdate={handleUpdate}
                triggerOperationUpdate={triggerOperationUpdate}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );


}

export default Summary;